export enum FileStatusEnum {
  Default,
  Scheduled,
  Queued,
  Uploading,
  Downloading,
  Deleting,
  Error,
  Impossible,
  Infected,
  Checking,
  SkipChecking,
  Done,
}

export enum FileCheckStatusEnum {
  /**
   * Новый файл(нужна проверка на вирусы)
   */
  New = 'NEW',
  /**
   * Файл на проверке
   */
  OnCheck = 'ON_CHECK',
  /**
   * Файл проверен(вирусов не найдено)
   */
  Checked = 'CHECKED',
  /**
   * Найдены вирусы
   */
  Reject = 'REJECTED',
  /**
   * Время проверки истекло
   */
  Timeout = 'TIMEOUT',
  /**
   * Проверка пропущена (для внутренних пользователей)
   */
  SkipCheck = 'SKIP_CHECK',
}
