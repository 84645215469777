export enum ErrorTypeEnum {
  Forbidden = 'forbidden',
  NotFound = 'not_found',
  InvalidGrant = 'invalid_grant',
  InvalidData = 'invalid_data',
  InvalidStatus = 'invalid_status',
  ConflictingState = 'conflicting_state',
  SmsLimitExceeded = 'sms_limit_exceeded',
  Expired = 'expired',
  UuidParsing = 'uuid_parsing',
  AlreadyExists = 'already_exists',
  NeedToAgree = 'need-to-agree',
  QuestionnaireVersionConflict = 'questionnaire_version_conflict',
  questionnaireRemovedFromPublication = 'questionnaire_removed_from_publication',
  questionnaireDeleted = 'questionnaire_deleted',
  VersionCollision = 'version_collision',
}
