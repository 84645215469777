<script setup lang="ts">
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import type { IDatePeriod } from '@/common/models/filters/date-period';
import { inject, type Ref, ref, watch } from 'vue';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import Filter from '@/common/components/filters/Filter.vue';
import DatePicker from '@/ui-kit/components/DatePicker.vue';
import TextField from '@/ui-kit/components/TextField.vue';
import IMask from 'imask';
import type { IDateFilterParams } from '@/common/models/filters/filter-model';
import { DateFormat } from '@/ui-kit/utils/dateFormat.util';

const props = withDefaults(defineProps<IDateFilterParams>(), {
  width: 440,
  confirmButtonText: 'Применить',
  needHours: true,
  datePickerWidth: 136,
  updateConfirmCallback: async () => true,
  needFormatFromAndTo: false,
});

const emits = defineEmits<(e: 'update:modelValue', value: IDatePeriod[]) => void>();

const isOpened = ref<boolean>(false);
const viewValue = ref<string>('');
const ranges = ref<Array<IDatePeriod>>(props.items);
const startValues = ref<Array<IDatePeriod>>(cloneDeep(props.items || props.modelValue || []));
const values = ref<IDatePeriod[]>([]);
const maskOptions = ref({
  overwrite: true,
  autofix: true,
  mask: 'HH:MM',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      placeholderChar: 'HH',
      from: 0,
      to: 23,
      maxLength: 2,
    },
    MM: {
      mask: IMask.MaskedRange,
      placeholderChar: 'MM',
      from: 0,
      to: 59,
      maxLength: 2,
    },
  },
});
const clearFiltersWatcher = inject<Ref<number>>('clearFilters');

initValues();

async function confirm(): Promise<void> {
  let isUpdateConfirmed = true;

  if (props.updateConfirmCallback) {
    isUpdateConfirmed = await props.updateConfirmCallback();
  }

  if (!isUpdateConfirmed) {
    return;
  }

  const res = values.value?.map((range) => {
    const endOfDayValues = ['00:00', '0000', '23:59', '2359'];
    const endOfDay = !range.endTime || endOfDayValues.includes(range.endTime);
    return {
      start: props.needHours
        ? formatFinalDateWithTime(range.start, range.startTime || null)
        : formatFinalDate(range.start),
      end: props.needHours
        ? formatFinalDateWithTime(range.end, range.endTime || null, endOfDay)
        : formatFinalDate(range.end, true),
    };
  });

  emits('update:modelValue', res);
  setViewValue(res);
  isOpened.value = false;
}

function setViewValue(values: IDatePeriod[]) {
  if (!values?.length) {
    viewValue.value = '';
  }

  const res = values
    ?.filter((item) => item.start || item.end)
    .map((item) => {
      if (item.start || item.end) {
        let start = item.start ? DateFormat.default(item.start, true, false, false, false) : '...';
        let end = item.end ? DateFormat.default(item.end, true, true) : '...';
        return `${start} — ${end}`;
      } else {
        return '';
      }
    });
  if (res.length) {
    viewValue.value = res.length > 1 ? res.join(' / ') : res[0];
  } else {
    viewValue.value = '';
  }
}

function viewValueUpdated(newViewValue: string): void {
  if (!newViewValue) {
    values.value = values.value.map(() => ({ start: null, end: null }));
    viewValue.value = '';
    confirm();
  }
}

function formatDate(date: Date): string {
  return moment(date).format('DD.MM.YY');
}

function initValues(): void {
  let ranges = cloneDeep(props.items || props.modelValue || []);
  ranges = ranges.map((range) => ({
    ...range,
    startTime: range.start ? moment(range.start).format('HH:MM') : '',
    endTime: range.end ? moment(range.end).format('HH:MM') : '',
  }));
  values.value = ranges;
}

function formatFinalDateWithTime(date: string, time = '00:00', endOfDay?: boolean): string | null {
  if (date) {
    const dateString = moment(date, 'YYYY-MM-DD').format('DD.MM.YY');
    let firstTwoDigits = time?.substring(0, 2);
    let lastTwoDigits = time?.substring(2);
    let formattedTime = `${firstTwoDigits}:${lastTwoDigits}`;
    let resultDate = moment(`${dateString} ${formattedTime}`, 'DD.MM.YY HH:mm');
    if (endOfDay) {
      resultDate = resultDate.endOf('day');
    }

    return moment(resultDate).toDate().toISOString();
  } else {
    return null;
  }
}

function formatFinalDate(date: string, endOfDay?: boolean): string | null {
  if (!date) {
    return null;
  }

  const dateString = moment(date, 'YYYY-MM-DD').format('DD.MM.YY');
  let resultDate = moment(`${dateString}`, 'DD.MM.YY');
  if (endOfDay) {
    resultDate = resultDate.endOf('day');
  }

  // возвращаем дату в формате 2024-07-08T11:49:22.912Z
  return moment(resultDate).toDate().toISOString();
}

values.value.forEach((item) => {
  watch(
    () => item.start,
    (newStart) => {
      if (newStart && !item.startTime) {
        item.startTime = '00:00';
      }
    },
  );
  watch(
    () => item.end,
    (newEnd) => {
      if (newEnd && !item.endTime) {
        if (props.needFormatFromAndTo) {
          item.endTime = '23:59';
        } else {
          item.endTime = '00:00';
        }
      }
    },
  );
  watch(
    () => item.startTime,
    (newStartTime) => {
      if (newStartTime === null || newStartTime === undefined || newStartTime === '' || newStartTime === '0000') {
        item.startTime = '00:00';
      }
    },
  );
  watch(
    () => item.endTime,
    (newEndTime) => {
      if (newEndTime === null || newEndTime === undefined || newEndTime === '' || newEndTime === '0000') {
        if (props.needFormatFromAndTo) {
          item.endTime = '23:59';
        } else {
          item.endTime = '00:00';
        }
      }
    },
  );
});

watch(
  () => clearFiltersWatcher?.value,
  () => {
    values.value = cloneDeep(startValues.value);
    setViewValue([]);
  },
);
</script>

<template>
  <Filter
    v-model:opened="isOpened"
    :label="label"
    :parent-ref="parentRef"
    :icon="IconPathEnum.NavigationArrowDown20PxSvg"
    :view-value="viewValue"
    :update-confirm-callback="updateConfirmCallback"
    :disabled="disabled"
    @update:view-value="viewValueUpdated($event)"
  >
    <div
      class="d-flex flex-column"
      :style="{ width: width + 'px' }"
    >
      <div class="d-flex flex-column">
        <div
          v-for="(range, index) in values"
          :key="ranges[index][titleKey]"
          class="date-range d-flex flex-column"
        >
          <div class="mm-body-medium-m mb16">{{ ranges[index][titleKey] }}</div>
          <div class="d-flex align-items-center justify-content-around">
            <div class="d-flex gap">
              <DatePicker
                v-model="range.start"
                :style="`width: ${datePickerWidth}px !important;`"
                placeholder="от"
                :format="formatDate"
                :max-date="range.end"
              />
              <TextField
                v-if="needHours"
                v-model="range['startTime']"
                size="small"
                class="time text-field-no-padding-40"
                icon-disabled
                :mask-options="maskOptions"
                placeholder="00:00"
                :clearable="false"
              />
            </div>
            <div class="dash-color mm-body-regular-s">—</div>
            <div class="d-flex gap">
              <DatePicker
                v-model="range.end"
                :style="`width: ${datePickerWidth}px !important;`"
                placeholder="до"
                :min-date="range.start"
                :format="formatDate"
              />
              <TextField
                v-if="needHours"
                v-model="range['endTime']"
                size="small"
                class="time text-field-no-padding-40"
                icon-disabled
                :mask-options="maskOptions"
                placeholder="00:00"
                :clearable="false"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary d-flex justify-content-center mt24"
        @click="confirm"
      >
        {{ confirmButtonText }}
      </button>
    </div>
  </Filter>
</template>

<style scoped lang="scss">
.date-range {
  &:not(:last-child) {
    margin-bottom: 24px !important;
  }
}

.w136 {
  width: 136px;
}

.time {
  width: 66px;
}

:deep(.mm-input__input) {
  padding: 10px 12px !important;
}

.dash-color {
  color: #bcc6c3;
}

.gap {
  gap: 8px;
}
</style>
