<template>
  <div class="pagination-container">
    <div
      v-if="isSelectablePageSize && dataCount > minPageSize"
      class="mm-pagination-page-size d-flex align-items-center justify-content-between pr32 pl32"
      :class="{'round-corners': dataCount > minPageSize && dataCount <= pageSize && !preventRounding}"
    >
      <div
        v-if="showOverallCount"
        class="overall-count"
      >
        Количество строк: <span class="counter">{{ dataCount }}</span>
      </div>
      <div
        class="page-size-selection d-flex"
        :class="{'align-center': !showOverallCount}"
      >
        <p class="mm-pagination-page-size-label">Показывать по:</p>

        <ul class="mm-pagination-page-size-list">
          <li
            v-for="(pageSizeElement, index) in pageSizeList"
            :key="index"
            class="mm-pagination-page-size-list-item"
            :class="{
              'mm-pagination-page-size-list-item-active': pageSizeElement === selectedPageSize,
              disabled
            }"
            @click="onPageSizeClick(pageSizeElement)"
          >
            {{ pageSizeElement }}
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="dataCount > pageSize"
      class="mm-pagination"
    >
      <button
        class="mm-block-light-gray"
        :disabled="isPageDownDisabled || disabled"
        @click="onPreviousPage"
      >
        <SvgIcon
          class="mm-pagination-arrow left"
          :src="IconPathEnum.NavigationArrowRightSmallSvg"
        />
        Назад
      </button>

      <div
        v-if="!isSupportBtnsHidden"
        class="mm-pagination-pages"
      >
        <div
          v-if="rangePages.length && !rangePages.includes(1)"
          class="mm-pagination-page"
          :class="{ disabled }"
          @click="onPageClick(1)"
        >
          1
        </div>

        <div
          v-if="isVisiblePreviousDots"
          class="mm-pagination-page dots"
        >
          <SvgIcon :src="IconPathEnum.ActionMoreDotsSvg" />
        </div>

        <template v-if="rangePages.length">
          <div
            v-for="(pageItem, key) in rangePages"
            :key="key"
            class="mm-pagination-page"
            :class="{ active: pageItem == activePage, disabled }"
            @click="onPageClick(pageItem)"
          >
            {{ pageItem }}
          </div>
        </template>

        <div
          v-else
          class="mm-pagination-page active"
        >
          1
        </div>

        <div
          v-if="isVisibleSubsequentDots"
          class="mm-pagination-page dots"
        >
          <SvgIcon :src="IconPathEnum.ActionMoreDotsSvg" />
        </div>

        <div
          v-if="rangePages.length && !rangePages.includes(internalSize)"
          class="mm-pagination-page"
          :class="{ active: internalSize == activePage, disabled }"
          @click="onPageClick(internalSize)"
        >
          {{ internalSize }}
        </div>
      </div>

      <button
        class="mm-block-light-gray"
        :disabled="isPageUpDisabled || disabled"
        @click="onNextPage"
      >
        Вперед
        <SvgIcon
          class="mm-pagination-arrow right"
          :src="IconPathEnum.NavigationArrowLeftSmallSvg"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { range } from '../utils/range.util';
import { DEFAULT_PAGE_SIZE_LIST } from '../constants/defaultPageSizeList.const';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import { computed, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    //Текущая страница
    page?: number;
    // Количество страниц
    size?: number;
    // Количество отображаемых соседних страниц
    siblingCount?: number;
    // Общее количество данных
    dataCount?: number;
    // Количество элементов на странице
    pageSize?: number;
    // Список досупных размеров
    pageSizeList?: Array<number>;
    // Должны ли отображаться контролы перемещения
    isSupportBtnsHidden?: boolean;
    // Отключить кнопку "Назад"
    isPrevBtnDisabled?: boolean;
    // Отключить кнопку вперед
    isNextBtnDisabled?: boolean;
    // Отключить весь компонент
    disabled?: boolean;
    // Разрешено ли изменение размера страницы
    isSelectablePageSize?: boolean;
    // Выбранный размер страницы
    selectedPageSize?: number;
    // Отображать общее количество строк
    showOverallCount?: boolean;
    // запрещает скруглять углы границ, т.к. есть следующий блок
    preventRounding?: boolean;
  }>(),
  {
    size: 1,
    siblingCount: 1,
    pageSize: 20,
    pageSizeList: () => DEFAULT_PAGE_SIZE_LIST,
  },
);

const emits = defineEmits<{
  (e: 'pageChanged', page: number): void;
  (e: 'pageSizeChanged', pageSize: number): void;
  (e: 'onNextPage'): void;
  (e: 'onPrevPage'): void;
}>();

const activePage = ref<number>(props.page);
const selectablePageSize = ref<number>(props.pageSize || props.pageSizeList[0]);

// eslint-disable-next-line vue/no-dupe-keys
const pageSize = computed<number>(() => updatePageSize());
const minPageSize = computed(() => Math.min(...props.pageSizeList));
const isVisibleSubsequentDots = computed(
  () => rangePages.value[rangePages.value.length - 1] <= internalSize.value - 2,
);
const isVisiblePreviousDots = computed(() => rangePages.value[0] > 2);
const isPageUpDisabled = computed<boolean>(
  () => props.isNextBtnDisabled || !internalSize.value || activePage.value === internalSize.value,
);
const isPageDownDisabled = computed<boolean>(
  () => props.isPrevBtnDisabled || activePage.value === 1,
);
const rangePages = computed(() => {
  const { siblingCount } = props;
  if (activePage.value === 1) {
    return range(
      activePage.value,
      Math.min(activePage.value + siblingCount + 1, internalSize.value),
    );
  }

  if (activePage.value === internalSize.value) {
    return range(Math.max(1, activePage.value - siblingCount - 1), internalSize.value);
  }

  return range(
    Math.max(activePage.value - siblingCount, 1),
    Math.min(activePage.value + siblingCount, internalSize.value),
  );
});

const internalSize = computed(
  () => (props.dataCount && Math.ceil(props.dataCount / pageSize.value)) ?? props.size,
);

function onPageSizeClick(size: number) {
  selectablePageSize.value = size;
  emits('pageSizeChanged', selectablePageSize.value);
}

function onPageClick(index: number) {
  activePage.value = index;
  emits('pageChanged', activePage.value);
}

function onNextPage() {
  activePage.value += 1;
  emits('pageChanged', activePage.value);
  emits('onNextPage');
}

function onPreviousPage() {
  activePage.value -= 1;
  emits('pageChanged', activePage.value);
  emits('onPrevPage');
}

function updatePageSize() {
  return props.isSelectablePageSize ? selectablePageSize.value : props.pageSize;
}

watch(
  () => props.page,
  (page) => (activePage.value = page),
);

watch(
  () => pageSize.value,
  () => {
    updatePageSize();
  },
);

defineExpose({
  isFirstPage: isPageDownDisabled,
  isLastPage: isPageUpDisabled,
});
</script>

<style lang="scss" scoped>
@import '@styles/base/common/_variables';

.mm-pagination {
  width: 100%;
  padding: 28px 40px;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mm-pagination-pages {
    display: flex;

    .mm-pagination-page {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      transition: $transition-speed ease;
      margin: 0 1px;
      font-size: 14px;

      &:hover {
        background-color: $light-gray;
      }

      &.disabled {
        pointer-events: none;
        color: $text-disabled;
      }

      &.active {
        background-color: $pagination-active-background-color;
        color: $pagination-active-color;
      }

      &.dots {
        cursor: auto;

        &:hover {
          background-color: inherit;
        }

        svg {
          transform: rotate(90deg) translateX(4px) scale(85%);
        }
      }
    }
  }

  .mm-block-light-gray {
    color: $text-dark-green;
    font-size: 14px;
    line-height: 20px;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;

    &:disabled {
      color: $text-disabled;
    }

    .mm-pagination-arrow {
      width: 6px;
      height: 8px;

      &.left {
        margin-right: 16px;
      }

      &.right {
        margin-left: 16px;
      }
    }
  }
}

.mm-pagination-page-size {
  border-left: 1px solid $gray-200;
  border-right: 1px solid $gray-200;
  background-color: $light-gray;
  height: 48px;
  &.round-corners {
    border-radius: 0 0 8px 8px;
  }
  .page-size-selection {
    margin-left: auto;
    &.align-center {
      margin-right: auto;
    }
  }
  .overall-count {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $text-dark-green;
    .counter {
      font-weight: 500;
    }
  }
  &-label {
    margin: 0;
    padding: 0;
    padding-right: 17px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $text-dark-green;
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    &.disabled {
      pointer-events: none;
      color: $text-disabled;
    }

    &-item {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $text-light-green;
      padding: 0 8px;
      border-right: 1px solid $dark-gray;

      &-active {
        color: $text-dark-green;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border: none;
        padding-right: 0;
      }

      &:hover {
        cursor: pointer;
        transition: 0.5s ease-in-out;
        opacity: 0.6;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-pagination {
    padding: 20px;

    .mm-block-light-gray {
      height: 36px;
      width: 36px;
      font-size: 0;
      padding: 14px;

      &:first-child {
        margin-right: 26px;
      }

      &:last-child {
        margin-left: 26px;
      }
    }

    &-arrow {
      width: 10px !important;
      height: 10px !important;
      margin: 0 !important;
    }

    &-pages {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &-page {
      margin: 0 4px !important;

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}
</style>
