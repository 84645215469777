<script lang="ts" setup>
import { computed,  onBeforeUnmount, onMounted, ref, watch } from 'vue';
import Notificator from '@/ui-kit/services/notificator.service';
import Loader from '@/ui-kit/utils/loaderHelper.util';
import SvgIcon from '@/ui-kit/components/SvgIcon.vue';
import { IconPathEnum } from '@/ui-kit/enums/iconPath.enum';
import ClipLoader from '@/ui-kit/components/ClipLoader.vue';
import { ValidatorsMessagesEnum } from '@/ui-kit/enums/validators.enum';
import type { IRepeatSMSCodeTimeoutResponse } from '@/views/auth/models/auth.model';
import ExceededLimitTimer from '@/views/auth/components/ExceededLimitTimer.vue';
import { cleanStringEscapeCharsAndSpacebar } from '@/common/utils/cleanStringEscapeCharsAndSpacebar.util';
import { ErrorTypeEnum } from '@/common/enums/error-type.enum';
import { useRouter } from 'vue-router';

const props = withDefaults(defineProps<{
  numbersCount?: number;
  requestFn?: (value: string) => Promise<void>;
  getMessageFn?: () => Promise<void>;
  getSmsTimeoutFn: () => Promise<IRepeatSMSCodeTimeoutResponse>;
  errorMessage?: ValidatorsMessagesEnum;
  disableValueCleaning?: boolean;
}>(), {
  numbersCount: 6,
  errorMessage: ValidatorsMessagesEnum.IncorrectSMSCode,
});

const router = useRouter();
const internalValue = ref<string>('');
const inputRef = ref<HTMLInputElement>();
const isLoading = Loader.getReactiveInstance();
const isGettingMessage = Loader.getReactiveInstance();
const timeToRetry = ref<number>();
const isRetryMessageCountdown = ref<boolean>(false);
const retryCountdownInterval = ref<number>();
const isBlockRetryCountdown = ref<boolean>(false);
const minuteInMilliseconds = 60_000;
const retryMessageTimeout = 90_000;
const oneSecond = 1000;
const isError = ref<boolean>(false);
const isFocus = ref<boolean>();
const numberAttemptsLeft = ref<number>(0);
const unblockTimerMsValue = ref<number>(0);

const countdownTime = computed<string>(() => {
  if (!timeToRetry.value) {
    return '';
  }

  return getTimeFromTimeInt(timeToRetry.value);
});

const isButtonVisible = computed(() => !isRetryMessageCountdown.value && !isBlockRetryCountdown.value);


function getTimeFromTimeInt(timeInt: number): string {
  let minutes: string = Math.floor(timeInt / minuteInMilliseconds).toString();
  let seconds = Math.floor((timeInt - Number(minutes) * minuteInMilliseconds) / oneSecond).toString();

  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }

  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

function onFocus(): void {
  inputRef.value?.focus();
  isFocus.value = true;
}

function onBlur(): void {
  inputRef.value.blur();
  isFocus.value = false;
}

function startRetryCountdownInterval(timeout?: number): void {
  isRetryMessageCountdown.value = true;
  timeToRetry.value = timeout || retryMessageTimeout;
  retryCountdownInterval.value = setInterval(() => {
    timeToRetry.value -= oneSecond;

    if (timeToRetry.value === 0) {
      clearInterval(retryCountdownInterval.value);
      isRetryMessageCountdown.value = false;
    }
  }, oneSecond);
}

async function onGetMessage(): Promise<void> {
  if (!props.getMessageFn) {
    return;
  }

  try {
    isGettingMessage.activate();
    await props.getMessageFn();
    await getSmsTimeout();
  }
  catch (error) {
    console.error(error);
    Notificator.showDetachedNotification('Произошла ошибка при запросе сообщения');
  }
  finally {
    isGettingMessage.deactivate();
  }
}
async function fetchCallback(): Promise<void> {
  if (!props.requestFn) {
    return;
  }

  try {
    isLoading.activate();
    onBlur();
    await props.requestFn(internalValue.value);
  }
  catch (error) {
    if (error.response.data?.errors?.find(error => error.code === ErrorTypeEnum.NotFound)) {
      await getSmsTimeout();
    }
    if (error.response.data?.errors?.find(error => error.code === ErrorTypeEnum.Forbidden)) {
      await getSmsTimeout();
      await router.push('/auth/access-denied');
    }
    if (error?.response?.status !== 404) {
      Notificator.showDetachedNotification('Произошла ошибка');
    }
    isError.value = true;
    console.error(error);
  }
  finally {
    isLoading.deactivate();
    onFocus();
  }
}

async function getSmsTimeout(): Promise<void> {
  if (!props.getSmsTimeoutFn) {
    return;
  }

  try {
    const timeoutRes = await props.getSmsTimeoutFn();

    if (timeoutRes?.attemptsLeft >= 0) {
      numberAttemptsLeft.value = timeoutRes?.attemptsLeft;
    }

    if (timeoutRes?.unblockTimerMs) {
      if (timeToRetry.value) {
        clearInterval(retryCountdownInterval.value);
        isRetryMessageCountdown.value = false;
        unblockTimerMsValue.value = timeoutRes?.unblockTimerMs;
      } else {
        unblockTimerMsValue.value = timeoutRes?.unblockTimerMs;
      }
      isBlockRetryCountdown.value = true
      return;
    }

    if (timeoutRes?.nextSmsTimerMs && !timeToRetry.value) {
      startRetryCountdownInterval(timeoutRes?.nextSmsTimerMs);
    }
  } catch (error) {
    console.error(error);
  }
}

function onKeyUp(event: KeyboardEvent): void {
  if (event.key === 'Backspace') {
    internalValue.value?.slice(0, -1);
  }
}

function onInput(event: Event) {
  isError.value = false;
  const input = event.target as HTMLInputElement;
  input.value = props.disableValueCleaning
    ? input.value.replace(/\D/g, '')
    : cleanStringEscapeCharsAndSpacebar(input.value.replace(/\D/g, ''));
  internalValue.value = input.value;
}

function clearTimer() {
  unblockTimerMsValue.value = 0;
  isBlockRetryCountdown.value = false;
}

watch(
  () => internalValue.value,
  (newValue: string) => {
    if (newValue?.length === props.numbersCount) {
      fetchCallback();
    }
  },
);

onBeforeUnmount(() => {
  if (retryCountdownInterval.value) {
    clearInterval(retryCountdownInterval.value);
  }
});

onMounted(async () => {
  await getSmsTimeout();
  onFocus();
});
</script>

<template>
  <div
    class="sms-input"
    :class="{
      'disabled': isLoading.value,
      'error': isError
    }"
  >
    <div class="sms-input--label">
      <div
        v-for="field in numbersCount"
        :key="field"
        class="sms-input--label__field"
        :class="{
          'focus' : internalValue?.length === field - 1 && isFocus
        }"
      >
        <span v-if="internalValue?.[field - 1]">
          {{ internalValue?.[field - 1] }}
        </span>
        <div
          v-else
          class="sms-input--label__field--empty"
        />
      </div>
    </div>

    <input
      v-model="internalValue"
      ref="inputRef"
      id="input"
      type="text"
      inputmode="numeric"
      @input="onInput"
      @focusin="onFocus"
      @blur="onBlur"
      @keyup="onKeyUp"
    >

    <div
      v-if="isError && numberAttemptsLeft"
      class="error"
    >
      {{ errorMessage }} <span>Осталось попыток: {{ numberAttemptsLeft }}</span>
    </div>

    <button
      v-if="isButtonVisible"
      class="btn get-message"
      :disabled="isGettingMessage.value"
      @click="onGetMessage"
    >
      <ClipLoader v-if="isGettingMessage.value" />
      <SvgIcon
        v-else
        :src="IconPathEnum.ActionUpdate"
      />
      Запросить код повторно
    </button>

    <div :key="countdownTime">
      <ExceededLimitTimer
        :unblock-timer-ms="unblockTimerMsValue"
        :attempts-left="numberAttemptsLeft"
        @clear="clearTimer"
      />

      <p v-if="isRetryMessageCountdown && !unblockTimerMsValue">
        Запросить код повторно можно через {{ countdownTime }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@styles/base/common/variables';

.sms-input {
  position: relative;

  &.disabled {
    pointer-events: none;

    .sms-input--label__field {
      background-color: $gray-100;
    }
  }

  &.error {
    .sms-input--label__field {
      border-color: $red;
    }
  }

  .error {
    margin-top: 4px;
    color: $red;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  p {
    margin-top: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $light-green;
  }

  .get-message {
    margin-top: 40px;
    display: flex;
    align-items: center;
    color: $link;
    padding: 0;
    gap: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;

    .mm-clip-loader {
      position: static;
      width: 20px;
      height: 20px;
      transform: none;
      margin-right: 4px;

      :deep(img) {
        width: 20px;
        height: 20px;
      }
    }
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 144px;
  }

  &--label {
    display: flex;
    gap: 8px;

    &__field {
      width: 77px;
      height: 144px;
      border-radius: 6px;
      border: 1px solid $gray-200;
      padding: 48px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all $transition-speed ease;

      &.focus {
        border: 1px solid $light-green;
      }

      &:has(.sms-input--label__field--empty) {
        align-items: flex-end;
      }

      span {
        font-size: 48px;
        font-weight: 500;
        line-height: 48px;
        color: $text-black;
      }

      &--empty {
        &::after {
          display: block;
          content: '';
          width: 24px;
          height: 4px;
          background-color: $text-disabled;
        }
      }
    }
  }
}
</style>
