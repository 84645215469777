import type { IAuthToken } from '@/common/models/api/auth/authToken.model';
import type { ILocalStorageToken } from '@/common/models/api/auth/localStorageToken.model';
import { useAuthApi } from '@/common/api/useAuthApi';
import type { AxiosInstance } from 'axios';
import { setPending } from '@/common/auth/axios-interceptor';
import moment from 'moment';
import { LAST_TOKEN_UPDATE_KEY } from '@/common/constants/lastTokenUpdate.const';
import { TOKEN_REFRESH_THRESHOLD_SECONDS } from '@/common/constants/tokenRefreshThresholdSeconds.const';
import { ACCESS_TOKEN_KEY } from '@/common/constants/accessTokenKey.const';

export function useAuthToken(): IUseAuthToken {
  return {
    saveToken: (token: IAuthToken): void => {
      saveTokenToLocalStorage(token, ACCESS_TOKEN_KEY);
    },
    getToken: async (useRefresh: boolean, axios?: AxiosInstance): Promise<ILocalStorageToken | null> => {
      const res = localStorage.getItem(ACCESS_TOKEN_KEY);
      if (res) {
        try {
          const token: ILocalStorageToken = JSON.parse(res);
          const lastUpdateFromStorage = localStorage.getItem(LAST_TOKEN_UPDATE_KEY);
          if (lastUpdateFromStorage) {
            const lastUpdate = moment(lastUpdateFromStorage);
            const currentDate = moment();
            const diff = currentDate.diff(lastUpdate, 'seconds');
            if (diff > TOKEN_REFRESH_THRESHOLD_SECONDS && useRefresh) {
              const authApi = useAuthApi(axios);
              setPending(true);
              const newToken = await authApi.refreshToken({refresh_token: token.refresh_token});
              return saveTokenToLocalStorage(newToken, ACCESS_TOKEN_KEY);
            } else {
              return token;
            }
          }
        } catch (e) {
          console.error(e);
          return null;
        }
      } else {
        return null;
      }
    },
    removeToken: (): void => {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
    },
    isAuthorized: (): boolean => {
      const res = localStorage.getItem(ACCESS_TOKEN_KEY);
      if(res) {
        const token: ILocalStorageToken = JSON.parse(res);
        const tokenTtl = moment(parseJwt(token.refresh_token).exp * 1000)
        const currentDate = moment()
        const diff = currentDate.diff(tokenTtl, 'seconds');
        if(diff < 0) {
          return true
        }
      }
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      return false
    },
  };
}

function parseJwt (token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}


export interface IUseAuthToken {
  saveToken: (token: IAuthToken) => void;
  getToken: (useRefresh: boolean, axios?: unknown) => Promise<ILocalStorageToken | null>;
  removeToken: () => void;
  isAuthorized: () => boolean;
}

function saveTokenToLocalStorage(token: IAuthToken, key: string): ILocalStorageToken {
  const localStorageData: ILocalStorageToken = {
    ...token,
    expiresAt: token.exp * 1000,
  };

  localStorage.setItem(
    key,
    JSON.stringify(localStorageData),
  );
  localStorage.setItem(LAST_TOKEN_UPDATE_KEY, new Date().toISOString());
  setPending(false);
  return localStorageData;
}
