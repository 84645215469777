import type { IElementOnPagePosition } from '@/ui-kit/models/utils/elementOnPagePosition.model';

export class ElementPositionUtil {

  public static getElementPositionInsideRelativeParent(
    element: HTMLElement,
  ): IElementOnPagePosition {
    const elementPosition = {
      top: 0,
      left: 0,
    };

    if (!element) {
      return elementPosition;
    }

    while (element.offsetParent && getComputedStyle(element).position !== 'relative') {
      elementPosition.top += element.offsetTop;
      elementPosition.left += element.offsetLeft;
      element = element.offsetParent as HTMLElement;
    }

    return elementPosition;
  }

  public static isIntersecting(elementA: HTMLElement, elementB: HTMLElement): boolean {
    const rectA = elementA.getBoundingClientRect();
    const rectB = elementB.getBoundingClientRect();
    return rectA.right > rectB.left && rectA.left < rectB.right && rectA.bottom > rectB.top && rectA.top < rectB.bottom;
  }
}
