import FilesService from '@/ui-kit/services/api/files.service';

export function usePolicyApi(): IPolicyApi {
  const baseApiUrl = `${import.meta.env.VITE_API_URL}/users/policies`;

  return {
    downloadPolicyFile: async (fileId: string, fileName: string): Promise<void> => {
      FilesService.fileDownloadByNativeHtml(`${baseApiUrl}/${fileId}/download/`, () => fileName);
    },
  };
}

export interface IPolicyApi {
  downloadPolicyFile: (fileId: string, fileName: string) => Promise<void>;
}
