<template>
  <div class="cookie-warning-content">
    Мы используем файлы cookie для сбора и хранения информации об использовании этого сайта на вашем устройстве.
    Продолжая использование нашего сайта, вы даёте согласие на обработку cookie в соответствии с <button
      class="cookie-warning-content-button"
      @click="downloadPrivacyPolicyFile"
    >
      Политикой по
      обработке персональных данных
    </button> посетителей сайта. Если вы хотите запретить обработку файлов cookie,
    отключите cookie в настройках вашего браузера.
  </div>
</template>

<script lang="ts" setup>
import { useTenantStore } from '@/stores/tenant.store';
import { privacyFileNameMap } from '@/common/constants/privacyFileNameMap.const';
import { PolicyFileTypeEnum } from '@/common/enums/policyFileType.enum';
import Notificator from '@/ui-kit/services/notificator.service';
import { usePolicyApi } from '@/common/api/usePolicyApi'

const tenantStore = useTenantStore();
const { downloadPolicyFile } = usePolicyApi();

async function downloadPrivacyPolicyFile() {
  try {
      await downloadPolicyFile(tenantStore?.tenant.policyPersonalFileId, privacyFileNameMap.get(PolicyFileTypeEnum.PrivacyPolicy))
    } catch (error) {
      Notificator.showDetachedNotification('Не удалось загрузить файл')
    }
}

</script>

<style scoped lang="scss">
@import '@assets/styles/base/common/_variables.scss';

:global(.cookie-warning-notification) {
  align-items: flex-start !important;
}

:global(.Vue-Toastification__container.bottom-center) {
  left: 50%;
  margin-left: -372px !important;
}

.cookie-warning-content {
  max-width: 636px;
  width: max(646px, 90vw);

  &-button {
    background: transparent;
    border: none;
    color: $accent-link-color;
  }
}
</style>
