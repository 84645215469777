import axios from 'axios';
import { useAuthToken } from '@/common/composables/useAuthToken';
import { ref, watch } from 'vue';
import { useProfileStore } from '@/stores/profile.store';
import { waitForCurrentRole } from '@/common/utils/waitForCurrentUser';
import { IGNORE_REFRESH_TOKEN, IGNORE_ACCESS_TOKEN } from '@/common/constants/routing.const';

export const pending = ref(false);
const isUnauthorized = ref(false); // Добавляем флаг для 401 ошибки

export const ACTIVE_ROLE_HEADER = 'Current-Role-Id';
export function setPending(value: boolean) {
  pending.value = value;
}

function waitForRequestWillBeAvailable(): Promise<void> {
  return new Promise((resolve) => {
    if (!pending.value) {
      resolve();
    }
    if (pending.value) {
      watch(() => pending.value, (value) => {
        if (!value) {
          resolve();
        }
      });
    }
  });
}

axios.interceptors.request.use(
  async function (config) {
    if (isUnauthorized.value) {
      // Прерываем выполнение запроса, если произошла 401 ошибка
      return Promise.reject(new axios.Cancel('Request cancelled due to 401 Unauthorized error'));
    }

    const ignoreToken = config.headers.get(IGNORE_ACCESS_TOKEN);
    const ignoreRefreshToken = config.headers.get(IGNORE_REFRESH_TOKEN);
    const { getToken } = useAuthToken();

    if (!ignoreToken) {
      await waitForRequestWillBeAvailable();
      const access_token = await getToken(!ignoreRefreshToken, axios);
      if (access_token && !ignoreToken) {
        config.headers.set('Authorization', `Bearer ${access_token.access_token}`);
      }
    }
    if (!config.url.includes('/users/current') && !config.url.includes('/auth') && !config.params?.skipAuth) {
      const userStore = useProfileStore();
      if (userStore.currentRole?.id) {
        config.headers.set(ACTIVE_ROLE_HEADER, '' + userStore.currentRole.id);
      } else if (!config.url.includes('/contact-info')) {
        await waitForCurrentRole();
        config.headers.set(ACTIVE_ROLE_HEADER, '' + userStore.currentRole.id);
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error?.response?.status;
    if (status === 401 && !window.location.href.includes('auth') ) {
      isUnauthorized.value = true; // Устанавливаем флаг при 401 ошибке
      const { removeToken } = useAuthToken();
      removeToken();
      window.location.href = '/auth/session-expired';
    }

    if (status === 403) {
      window.location.href = '/auth/access-denied';
    }

    return Promise.reject(error);
  },
);

export default axios;
